import React from 'react';
import './Footer.scss';
import "../components/FancyLink";
import FancyLink from '../components/FancyLink';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';

function Footer() {

    return (
        <div className="footer-wrapper">
            <div class="footer-content">
                <div class="footer-social-links">
                    <svg class="footer-social-amoeba-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 54">
                        <path class="footer-social-amoeba-path" d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"></path>
                    </svg>
                    <a className="footer-social-link instagram" href="https://www.instagram.com/wm_mcp" target="_blank">
                        <FaInstagram fontSize="1.6em" />
                        <span class="hidden-link-text">Instagram</span>
                    </a>
                    <a className="footer-social-link linkedin" href="https://www.linkedin.com/company/wmmcp" target="_blank">
                        <FaLinkedin fontSize="1.4em" />
                        <span class="hidden-link-text">LinkedIn</span>
                    </a>
                    <a class="footer-social-link twitter" href="https://twitter.com/wm_mcp" target="_blank">
                        <span class="hidden-link-text">Youtube</span>
                        <RiTwitterXFill fontSize="1.6em" />
                    </a>
                    <a class="footer-social-link youtube" href="https://youtu.be/TqgStDcsD7g" target="_blank">
                        <span class="hidden-link-text">Youtube</span>
                        <svg class="footer-social-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                            <path class="footer-social-icon-path" d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"></path>
                        </svg>
                    </a>
                </div>
            </div>

            <div class="footer-copyright">
                <div class="footer-copyright-wrapper">
                    <div className="footer-copyright-mailto-wrapper">
                        <FancyLink className="footer-copyright-mailto" link="mailto:mcp.superuser@gmail.com" text="mcp.superuser@gmail.com" width="187px" height="20px" />
                    </div>
                    <div></div>
                    <div>
                        <p class="footer-copyright-text">
                            <a class="footer-copyright-link"> ©2024. | Designed By: Min Kim. | All rights reserved. </a>
                        </p>
                    </div>
                    <div></div>
                    <div className="footer-copyright-privacy-wrapper">
                        <FancyLink className="footer-copyright-privacy" link="/privacypolicy" text="Privacy Policy" width="100px" height="20px" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer; 